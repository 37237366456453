import { useEffect, useState } from "react";
import ResourceContext from "./ResourceContext";
import axios from "axios";

const ResourceState = ({ children }) => {
    const [resources, setResources] = useState([]);
    const [loading, setLoading] = useState(true);
    const initData = window.Telegram.WebApp.initData;
    // const initData = "query_id=AAG7g8UsAwAAALuDxSzrAfjA&user=%7B%22id%22%3A7193592763%2C%22first_name%22%3A%22Lohith%22%2C%22last_name%22%3A%22Reddy%22%2C%22language_code%22%3A%22en%22%2C%22allows_write_to_pm%22%3Atrue%7D&auth_date=1719923929&hash=dd55d21fd9785f80795fc39a86daa04944b7c733b489362317ac74019adaf74a";


    const [tasks, setTasks] = useState([]);
    const [dailyReward, setDailyReward] = useState([]);
    const [referrals, setReferrals] = useState([]);
    const [trigger,setTrigger] = useState(false);
    const[Leaders,setLeaders] = useState([]);
    const [collectables,setCollectables] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);

                const validateRequest = axios.post("https://mechamon.glitch.me/validate", null, {
                    params: {
                        initData: initData,
                        refer: window.Telegram.WebApp.initDataUnsafe?.start_param
                    }
                });

                const tasksRequest = axios.post("https://mechamon.glitch.me/tasks", null, {
                    params: { initData: initData }
                });

                const referralsRequest = axios.post("https://mechamon.glitch.me/referral/getReferrals", null, {
                    params: { initData: initData }
                });
                const leadersRequest = axios.post('https://mechamon.glitch.me/leaderboard',
                    null, {
                        params: {
                            initData: initData
                        }
                    }
                )

                const [validateResponse, tasksResponse, referralsResponse,leadersResponse] = await Promise.all([
                    validateRequest,
                    tasksRequest,
                    referralsRequest,
                    leadersRequest
                ]);

                setLeaders(leadersResponse.data);
                setResources(validateResponse.data.resource);
                setCollectables(validateResponse.data.collectables);
                setTasks(tasksResponse.data.task.tasks);
                setDailyReward(tasksResponse.data.dailyReward.details);
                setReferrals(referralsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [trigger]);

    return (
        <ResourceContext.Provider value={{ Leaders,initData,resources, loading,setTrigger,setCollectables,collectables,tasks, dailyReward, referrals,setDailyReward,setResources,setTasks }}>
            {children}
        </ResourceContext.Provider>
    );
};

export default ResourceState;
