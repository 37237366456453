import React, { useState, useEffect, useContext } from 'react';
import ResourceContext from './context/ResourceContext';
import './Leaderboard.css';

function Leaderboard() {
  const {Leaders,resources,loading,setResources} = useContext(ResourceContext);
  const [leaders, setLeaders] = useState([]);
  const [tokens, setTokens] = useState(null);
  const [bots, setBots] = useState(0);
  const [rank, setRank] = useState(0);

  useEffect(() => {
    if (!loading) {
      console.log('Leaders:', Leaders);
      setLeaders(Leaders);
      setTokens(resources.tokens);
      setBots(resources.bots);
      if(Leaders.findIndex((leader) => leader.rank!=-1 )+1 ==0){
        setRank("100+")
      }else
      setRank(Leaders.findIndex((leader) => leader.rank!=-1 )+1)

    }
  }, [loading]);

  return (
    <div className='leaderboard-body'>
      <div className="stats">
      <div className='stats-booster'>
          <div className="highlight tokens">
            <div className="logo">
            </div>
            {tokens}
          </div>
        </div>
          <div> <span className="highlight bots">🤖{bots}</span></div>
          <div className="bots">
                 #{rank}
              </div>
      </div>
      <ul className="leaderboard-titles">
      <li key={"Title"} className='leader title'>
          <div className="rank">Rank</div>
          <div className="name">Name</div>
          <div className="bots">Bots</div>
        </li>
      </ul>
      <ul className='leaderboard'>
        {leaders.map((leader, index) => (
          <li key={index} className='leader'>
            <div className="rank">
              #{index + 1}
            </div>
            <div className="name">
              {leader.username.split(' ')[0] }
              </div>
              <div className="bots">
                 {leader.bots}🤖
              </div>

          </li>
        ))}
      </ul>
    </div>
  );
}

export default Leaderboard;
