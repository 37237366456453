import React from 'react'
import './Desktop.css'
import img from './img/L2-mecha-mining.jpeg'

const Desktop = () => {
  return (
    //display a stylish message to the user to use the mobile version of Telegram to play the game
    <div className='background'>
        <img src={img} width={250} height={250} alt="Nah!" />
        <a href="https://t.me/mechamon_bot" className='play-link'> Play </a>
        <h2 className='message'>Please use the mobile version of Telegram to play this game.</h2>

    </div>
  )
}

export default Desktop
